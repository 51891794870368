import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Layout from '../../../../components/Layout/Layout'
import { toast } from 'react-toastify'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../context/auth'
import { Modal, Button } from 'antd'
import { saveAs } from 'file-saver'
import MobileMismatchQty from './MobileMismatchQty'
import AdminMobileCheckListTable from './CheckListTable'
import MobileQtyLog from './MobileQtyLog'

const AdminMobileCheckList = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [searchData, setSearchData] = useState('')
  const [focusBarcodeInput, setFocusBarcodeInput] = useState(false)
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(null)
  const [currentItemDetails, setCurrentItemDetails] = useState({})
  const [editMode, setEditMode] = useState(null)
  const [editField, setEditField] = useState(null)
  const [originalValues, setOriginalValues] = useState({})
  const [filterQuery, setFilterQuery] = useState('')

  const barcodeInputRef = useRef(null)
  const [auth] = useAuth()
  const userId = auth?.user?._id

  const [mismatchItems, setMismatchItems] = useState([])
  const [visibleDiffQtyModal, setVisibleDiffQtyModal] = useState(false)

  useEffect(() => {
    const shouldFetch = filterQuery.length > 3 || filterQuery === ''
    if (shouldFetch) {
      fetchData(page)
    }
  }, [page, filterQuery])

  useEffect(() => {
    if (filterQuery.length > 3 || filterQuery === '') {
      setPage(1)
    }
  }, [filterQuery])

  const fetchData = async (newPage = 1, showToast = false) => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check-mobile/get-mobile-data/${id}`,
        {
          params: {
            userId,
            page: newPage,
            limit: 30,
            search: filterQuery,
          },
        }
      )
      const { data, totalPages } = response.data
      if (newPage === 1) {
        setItems(data)
      } else {
        setItems((prev) => [...prev, ...data])
      }
      setHasMore(newPage < totalPages)
      if (showToast) {
        toast.success('Data is up to date')
      }
    } catch (error) {
      console.error('Failed to fetch stock list data:', error)
      setItems([])
    } finally {
      setIsLoading(false)
    }
  }

  const handleRefresh = () => {
    setPage(1)
    fetchData(1, true)
  }

  useEffect(() => {
    if (focusBarcodeInput) {
      barcodeInputRef.current?.focus()
      setFocusBarcodeInput(false)
    }
  }, [focusBarcodeInput])

  const clearInput = () => {
    setSearchData('')
    setFocusBarcodeInput(true)
  }

  const getSearchContent = (value) => {
    setSearchData(value)
  }

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && searchData.trim()) {
      await scanResult()
    }
  }

  const scanResult = async () => {
    try {
      const { data } = await axios.post(
        '/api/v1/admin/stock-check-mobile/scan-mobile-stock',
        { searchData, id, userId }
      )
      if (data.products.length === 0) {
        toast.info('No records found')
      } else {
        updateScannedProduct(data.products[0])
        setLastUpdate(new Date())
      }
    } catch (error) {
      toast.error('Item not exist in ER Shop check list')
    } finally {
      clearInput()
    }
  }

  const updateScannedProduct = (scannedProduct) => {
    const userCode = auth?.user?.code

    setItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item._id === scannedProduct._id
      )

      let newCheckQty = scannedProduct.checkQty || 0
      if (existingItem) {
        newCheckQty = existingItem.checkQty + 1
      } else {
        // If the product is new, it should already have the correct checkQty from the backend
        newCheckQty = scannedProduct.checkQty + 1 // Increment the scanned product's qty
      }

      // Prepare the updated product with the new quantity, user code, and date
      const updatedProduct = {
        ...scannedProduct,
        checkQty: newCheckQty, // Set the incremented checkQty
        lastUptByCode: userCode, // Update with the current user's code
        lastUptDate: new Date(), // Set the current timestamp
      }

      // Check if the item already exists in the list
      const isItemInList = existingItem !== undefined

      // If the scanned item is new (not in the visible list), remove the last row
      let updatedItems
      if (isItemInList) {
        // If the item exists, move it to the top and update its values
        updatedItems = [
          updatedProduct,
          ...prevItems.filter((item) => item._id !== scannedProduct._id),
        ]
      } else {
        // If the item doesn't exist, append it to the top and remove the last row
        updatedItems = [
          updatedProduct,
          ...prevItems.slice(0, prevItems.length - 1), // Remove the last row
        ]
      }

      return updatedItems // Return the updated list
    })
  }

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1) // Load next page when the last element is visible
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const toggleEdit = (itemId, field, currentValue) => {
    if (editMode === itemId && editField === field) {
      setEditMode(null)
      setEditField(null)
      // Optionally reset to original value if not saved
      setItems((currentList) =>
        currentList.map((item) =>
          item._id === itemId
            ? {
                ...item,
                [field]: originalValues[`${itemId}-${field}`] || item[field],
              }
            : item
        )
      )
    } else {
      setEditMode(itemId)
      setEditField(field)
      // Set original value on first edit toggle
      setOriginalValues((prev) => ({
        ...prev,
        [`${itemId}-${field}`]: currentValue,
      }))
    }
  }

  const handleQtyKeyPress = (event, itemId, type) => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent default form submission behavior
      saveQty(itemId, type) // Make sure to pass the correct type
    }
  }

  const handleQtyChange = (itemId, value, type) => {
    setItems((currentList) =>
      currentList.map((item) =>
        item._id === itemId ? { ...item, [type]: value } : item
      )
    )
  }

  const saveQty = async (itemId, type) => {
    const item = items.find((item) => item._id === itemId)
    if (!item) return

    const originalValue = Number(
      String(originalValues[`${itemId}-${type}`]).trim()
    )
    const updatedValue = Number(String(item.checkQty).trim())
    if (originalValue === updatedValue) {
      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
      return
    }

    try {
      await axios.patch(
        `/api/v1/admin/stock-check-mobile/update-mobile-qty/${itemId}`,
        {
          checkQty: updatedValue,
          userId,
        }
      )

      const userCode = auth?.user?.code
      const updatedProduct = {
        ...item,
        checkQty: updatedValue, // Set the updated checkQty
        lastUptByCode: userCode, // Update with the current user's code
        lastUptDate: new Date(), // Set the current timestamp
      }

      setItems((prevItems) => [
        updatedProduct,
        ...prevItems.filter((item) => item._id !== itemId),
      ])

      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
    } catch (error) {
      toast.error(`Failed to update ${type}.`)
    }
  }

  const handleFilterChange = (value) => {
    setFilterQuery(value)
  }

  const handleRemarkChange = (itemId, value) => {
    setItems((currentList) =>
      currentList.map((item) =>
        item._id === itemId ? { ...item, remark: value } : item
      )
    )
  }

  const handleRemarkKeyPress = (event, itemId) => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent default form submission behavior
      saveRemark(itemId) // Save the remark on Enter key press
    }
  }

  const saveRemark = async (itemId) => {
    const item = items.find((item) => item._id === itemId)
    if (!item) return

    const originalRemark = (originalValues[`${itemId}-remark`] || '').trim()
    const updatedRemark = (item.remark || '').trim()
    console.log(originalRemark)
    console.log(updatedRemark)

    if (originalRemark === updatedRemark) {
      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
      return
    }

    try {
      await axios.patch(
        `/api/v1/admin/stock-check-mobile/update-mobile-remark/${itemId}`,
        {
          remark: item.remark,
          userId,
        }
      )

      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
      fetchData()
    } catch (error) {
      toast.error('Failed to update remark.')
    }
  }

  const openLogModal = (itemId) => {
    const item = items.find((item) => item._id === itemId)
    if (item) {
      setCurrentItemId(itemId)
      setCurrentItemDetails({
        itemCode: item.itemCode,
        description: item.description,
      })
      setVisibleLogModal(true)
    }
  }

  const closeModal = (e) => {
    e.preventDefault()
    setVisibleLogModal(false)
  }

  const exportFile = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check-mobile/export/${id}`,
        { responseType: 'blob' }
      )

      // Extract filename from content-disposition header
      const contentDisposition = response.headers['content-disposition']
      let filename = 'download.xlsx' // Default filename if not specified
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1]
        }
      }

      saveAs(response.data, filename)
    } catch (error) {
      console.error('Failed to download the file:', error)
      toast.error('Failed to download the file.')
    }
  }

  const resetData = async () => {
    const confirmed = window.confirm('Confirm Reset All Data?')
    if (confirmed) {
      try {
        await axios.delete(`/api/v1/admin/stock-check-mobile/reset-data/${id}`)
        toast.success('Data reset successfully!')
        fetchData(1) // Refetch data after reset
      } catch (error) {
        toast.error('Failed to reset data.')
        console.error('Failed to reset data:', error)
      }
    }
  }

  const fetchMismatchData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check-mobile/get-mobile-data/mismatch/${id}`,
        {
          params: {
            limit: 50, // Limit to 50 records
          },
        }
      )
      setMismatchItems(response.data.data)
      setVisibleDiffQtyModal(true) // Open the modal after fetching the data
    } catch (error) {
      console.error('Failed to fetch mismatch data:', error)
      toast.error('Failed to fetch mismatch data.')
    }
  }

  const closeMismatchModal = () => {
    setVisibleDiffQtyModal(false)
  }

  return (
    <Layout title='ER Shop Check List'>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{ width: '100%', position: 'fixed', height: '120px' }}
      >
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex'>
            <input
              type='text'
              className='form-control'
              style={{ width: '250px' }}
              placeholder='Search Item Code or Desc'
              onChange={(e) => handleFilterChange(e.target.value)}
              value={filterQuery}
            />
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={() => setFilterQuery('')}
            >
              Clear
            </button>
          </div>

          <div className='fw-bold' style={{ fontSize: '18px' }}>
            Mobile Check List
          </div>
          <button
            type='button'
            className='btn btn-outline-danger btn-sm'
            onClick={resetData}
          >
            Reset All Data
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() =>
              navigate(`/admin/stock-check/details/${id}`, {
                state: { id: id },
              })
            }
          >
            Back
          </button>
        </div>
        <div className='col-auto' style={{ display: 'flex' }}>
          <input
            type='text'
            className='form-control'
            placeholder='Scan / Enter Item Code'
            onChange={(e) => getSearchContent(e.target.value)}
            onKeyDown={handleKeyPress}
            value={searchData}
            style={{ flex: 1 }}
            autoFocus
            ref={barcodeInputRef}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={clearInput}
            style={{ marginLeft: '0px' }}
          >
            Clear
          </button>

          <button
            type='button'
            className='btn btn-outline-success btn-sm ms-5'
            onClick={() => exportFile()}
          >
            Export List
          </button>

          <button
            type='button'
            className='btn btn-outline-warning btn-sm ms-5'
            onClick={() => fetchMismatchData()}
          >
            Show Qty Mismatch
          </button>

          <button
            type='button'
            className='btn btn-outline-info btn-sm ms-3'
            onClick={handleRefresh}
          >
            <i class='bi bi-arrow-clockwise'></i>
          </button>
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <AdminMobileCheckListTable
            items={items}
            lastElementRef={lastElementRef}
            editMode={editMode}
            editField={editField}
            handleQtyChange={handleQtyChange}
            saveQty={saveQty}
            handleQtyKeyPress={handleQtyKeyPress}
            toggleEdit={toggleEdit}
            openLogModal={openLogModal}
            handleRemarkChange={handleRemarkChange}
            handleRemarkKeyPress={handleRemarkKeyPress}
            saveRemark={saveRemark}
            isLoading={isLoading}
            currentPage={page}
          />
        </div>
      </div>

      <Modal
        closable={false}
        onCancel={() => setVisibleLogModal(false)}
        open={visibleLogModal}
        width={'70%'}
        footer={[
          <Button key='cancel' onClick={() => setVisibleLogModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <MobileQtyLog
          closeLogModal={closeModal}
          itemId={currentItemId}
          itemDetails={currentItemDetails}
          lastUpdate={lastUpdate}
        />
      </Modal>

      <Modal
        title='Quantity Mismatch'
        closable={false}
        open={visibleDiffQtyModal}
        width='70%'
        onCancel={closeMismatchModal} // Add onCancel to allow closing the modal
        footer={[
          <Button key='close' onClick={closeMismatchModal}>
            Close
          </Button>,
        ]}
      >
        <MobileMismatchQty mismatchItems={mismatchItems} />
      </Modal>
    </Layout>
  )
}

export default AdminMobileCheckList
