import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import AddNewTracking from './AddNewTracking'
import axios from 'axios'
import moment from 'moment'
import UpdateStatusModal from './UpdateStatusModal'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import TrackingLogModal from './TrackingLog'

const AdminTrackingList = () => {
  const [trackings, setTrackings] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [currentStatus, setCurrentStatus] = useState('')
  const [currentTrackingId, setCurrentTrackingId] = useState(null)
  const [currentTrackingNo, setCurrentTrackingNo] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showLogModal, setShowLogModal] = useState(false)
  const [trackingLog, setTrackingLog] = useState([])

  useEffect(() => {
    fetchTrackings()
  }, [])

  const fetchTrackings = async () => {
    try {
      const response = await axios.get('/api/v1/admin/tracking/list')
      setTrackings(response.data.data)
    } catch (error) {
      console.error('Error fetching trackings:', error)
    }
  }

  const handleClose = () => {
    setShowModal(false)
    fetchTrackings()
  }

  const handleStatusClick = (tracking) => {
    setCurrentStatus(tracking.status)
    setCurrentTrackingId(tracking._id)
    setShowStatusModal(true)
  }

  const updateStatus = async (newStatus) => {
    try {
      const response = await axios.patch(
        `/api/v1/admin/tracking/update-status/${currentTrackingId}`,
        { status: newStatus }
      )
      if (response.data.success) {
        console.log('Status updated successfully:', response.data)
        fetchTrackings()
      } else {
        throw new Error('Failed to update status')
      }
    } catch (error) {
      console.error('Error updating status:', error)
      alert('Failed to update status')
    }
  }

  const handleTrackPosShipment = async () => {
    setIsLoading(true)
    try {
      await axios.post('/api/v1/admin/tracking/pos-shipment')
      fetchTrackings() // Refresh the tracking list after the operation
      toast.success('Tracking Completed')
    } catch (error) {
      console.error('Error fetching tracking data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInfoClick = async (trackingId, trackingNo) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/tracking/log/${trackingId}`
      )
      setTrackingLog(response.data)
      setCurrentTrackingNo(trackingNo)
      setShowLogModal(true)
    } catch (error) {
      console.error('Error fetching tracking log:', error)
      alert('Failed to fetch tracking log')
    }
  }

  const handleDeleteTracking = async (trackingId) => {
    if (window.confirm('Confirm delete this tracking?')) {
      try {
        await axios.delete(`/api/v1/admin/tracking/delete/${trackingId}`)
        fetchTrackings()
      } catch (error) {
        console.error('Error deleting tracking:', error)
        alert('Failed to delete tracking')
      }
    }
  }

  return (
    <Layout title={'Tracking List'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-1'>
          <button
            type='button'
            style={{ marginRight: '30px' }}
            className='btn btn-outline-success btn-sm'
            onClick={handleTrackPosShipment}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Start Tracking'
            )}
          </button>
          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            onClick={() => setShowModal(true)}
          >
            Add New Tracking
          </button>
        </div>
        <div
          className='fw-bold mb-2'
          style={{ fontStyle: 'italic', color: 'red' }}
        >
          Use this only to track Pos Malaysia deliveries
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Tracking No.</th>
              <th scope='col'>Tracking Data</th>
              <th scope='col'>Status</th>
              <th scope='col'>Recipient</th>
              <th scope='col'>Type</th>
              <th scope='col'>Remark</th>
              <th scope='col'></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {trackings.map((tracking) => (
              <tr key={tracking._id}>
                <td style={{ width: '100px' }}>
                  {moment(tracking.insertDate).format('YYYY-MM-DD')}
                </td>
                <td>{tracking.trackingNo}</td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleInfoClick(tracking._id, tracking.trackingNo)
                      }
                    >
                      <i
                        className='bi-info-circle-fill'
                        style={{ color: '#00a6ff' }}
                      ></i>
                    </span>
                    <span style={{ marginLeft: '10px', textIndent: '-2px' }}>
                      {tracking.data}
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    color:
                      tracking.status === 1
                        ? '#28a745'
                        : tracking.status === 2
                        ? '#007bff'
                        : tracking.status === 3
                        ? '#ffc107'
                        : '',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleStatusClick(tracking)}
                >
                  {tracking.status === 1
                    ? 'Delivered'
                    : tracking.status === 2
                    ? 'Shipped'
                    : tracking.status === 3
                    ? 'Pending Shipment'
                    : ''}
                </td>
                <td>{tracking.recipient}</td>
                <td style={{ width: '120px' }}>
                  {tracking.type === 1 ? 'Church/PSO' : 'Online Order'}
                </td>
                <td>{tracking.remark}</td>
                <td className='text-end'>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDeleteTracking(tracking._id)}
                  >
                    <i
                      className='bi bi-trash3'
                      style={{ color: '#dc3545' }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddNewTracking show={showModal} handleClose={handleClose} />
      <TrackingLogModal
        show={showLogModal}
        onHide={() => setShowLogModal(false)}
        logs={trackingLog}
        trackingNo={currentTrackingNo}
      />
      <UpdateStatusModal
        show={showStatusModal}
        handleClose={() => setShowStatusModal(false)}
        currentStatus={currentStatus}
        updateStatus={updateStatus}
      />
    </Layout>
  )
}

export default AdminTrackingList
