import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  return (
    <Layout title={'Admin Dashboard'}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/warehouse'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Warehouse
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/pre-order/books/list'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Pre-Order
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/packing-list'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Packing List
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/tracking-list'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Track Delivery
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/stock-check-list'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Stock Check
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/users'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Users
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AdminDashboard
