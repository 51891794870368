import React from 'react'

const ErMismatchQty = ({ mismatchItems }) => {
  return (
    <div>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Description</th>
            <th>Item Group</th>
            <th>Item Type</th>
            <th>Qty</th>
            <th>Physical Qty</th>
          </tr>
        </thead>
        <tbody>
          {mismatchItems.length > 0 ? (
            mismatchItems.map((item) => (
              <tr key={item._id}>
                <td>{item.itemCode}</td>
                <td>{item.description}</td>
                <td>{item.itemGrp}</td>
                <td>{item.itemType}</td>
                <td>{item.erShopQty}</td>
                <td>{item.checkQty}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan='6'>No mismatched items found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ErMismatchQty
